import React, { useState , useEffect,useContext } from "react";
import Header from './Header'
import {
  BrowserRouter as Router,
  Routes,
  useNavigate,
  Route,
  Link,
  useParams,
  useLocation,

} from 'react-router-dom';
import {get_blog,get_base_url,get_category} from './API/api';
import ReactPaginate from 'react-paginate';

import { LanguageContext } from './languagecontext'

import ContactForm from "./ContactForm";
import Footer from './Footer'

import { useTranslation } from 'react-i18next'

export default function Blog(props) {
  const { t, i18n } = useTranslation()
  const {language_selected} = useContext(LanguageContext);
  const {state} = useLocation();
  // alert(JSON.stringify(state))

    const [blog,set_blog] = useState([])
    const [blog_first,set_blog_first] = useState([])
    const [category,set_category] = useState([])
    const [offset,setoffset ] = useState(0)
    const [limit,setLimit] = useState(4)
    const [pagecount , setpagecount] = useState(0)
    const [base_url , set_base_url ] = useState("")
    const [keyword , set_keyword ] = useState("")
    const [blog_popular,set_blog_popular] = useState([])
    const [array_category,set_array_category] = useState([state == null ? '' : state.category_id ])
    const handleArrayClick = (category_id)=>{
      set_array_category(prev => prev.concat(category_id))

    }
    const removeArrray = (category_id)=>{
      // set_array_category()
      set_array_category(array_category.splice(array_category.indexOf(category_id),1))

    }
    const navigate = useNavigate();
    const handleInputChange = (val) => {
      console.log(JSON.stringify(val))
          set_keyword(val)
  }
    const get_data = () => {

      // get_blog({language_id : language_selected}).then(blog_data => {
      //   set_blog(blog_data.data)
      //   // alert(JSON.stringify(blog_data.find(arr=>arr.isFirst == "1")))


      //   set_blog_first((blog_data.data).find(arr=>arr.isFirst == "1"))
      // });
    
      get_base_url({language_id : language_selected}).then(base_url => {
        set_base_url(base_url)  

      })
      get_category({language_id : language_selected}).then(category => {
        set_category(category)  
      })
   }
   function go_to_single(slug){
  
    navigate('/blog/' + slug, { state: { data : blog.find(arr=>arr.slug == slug) , base_url : base_url,category : category} });
  
   }
   const handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * limit);
    setoffset(offset)
    // this.setState({ offset: offset }, () => {
    //   this.loadCommentsFromServer();
    // });
  };
  useEffect(()=>{
    get_data()

  },[language_selected])
   useEffect(()=>{
      
      get_blog({language_id : language_selected,offset : offset , limit : limit,keyword : keyword,category : array_category}).then(blog_data => {
        // alert(blog_data.total_count)
        if((blog_data.data) != undefined ){
          set_blog(blog_data.data)

        set_blog_first((blog_data.data).find(arr=>arr.isFirst == "1"))
          let popular_post = []
          for (let index = 0; index < (blog_data.data).length; index++) {
            const element = (blog_data.data)[index];
            popular_post.push(element);
          }
          set_blog_popular(popular_post)

        }else{
          set_blog_first([])
          set_blog([])

        }
        let pagecount = Math.ceil(blog_data.total_count / limit);
        setpagecount(pagecount)
        // alert(JSON.stringify(blog_data.find(arr=>arr.isFirst == "1")))
      });
    
   },[offset,keyword,array_category,language_selected])
    return (
    <div>
        <header id="home" style={{height: '60vh'}}>
        {/* Background Image */}
        <div className="bg-img" style={{backgroundImage: 'url("./img/background1.jpg")'}}>
          <div className="overlay" />

        </div>
        {/* /Background Image */}
        {/* Nav */}
        <Header></Header>

        {/* /Nav */}
        {/* home wrapper */}
        <div className="home-wrapper">
          <div className="container">
            <div className="row">
              {/* home content */}
              <div className="col-md-10 col-md-offset-1">
                <div className="home-content">
                  <h1 className="white-text">{t('blog')}</h1>
                  {/* <p className="white-text">Morbi mattis felis at nunc. Duis viverra diam non justo. In nisl. Nullam sit amet magna in magna gravida vehicula. Mauris tincidunt sem sed arcu. Nunc posuere.
                  </p> */}
                    <a className="main-btn" href="/blog#blog_section">{t('getstarted')}</a>
                </div>
              </div>
              {/* /home content */}
            </div>
          </div>
        </div>
        {/* /home wrapper */}
      </header>
      <div id="blog" className="section md-padding">
        {/* Container */}
        <div className="container" id="blog_section">
          {/* Row */}
          <div className="row">
            <div className="col-12 text-center mb-5">
              <h2 className="mb-4 section-title">{t('ourblog')}</h2>
            </div>
            {/* Main */}

            <main id="main" className="col-md-9">
            {(blog_first) != undefined ? 
              
              <div className="blog">
                <a href="blog-single.html">
                  <div className="blog-img">
                    <img className="img-responsive" src={base_url + blog_first.banners} alt="" />
                  </div>
                </a>
                <div className="blog-content">
                  <ul className="blog-meta">
                    {/* <li><i className="fa fa-user" />John doe</li> */}
                    <li><i className="fa fa-clock-o" />{blog_first.created_at}</li>
                  </ul>
                  <h3>{blog_first.title}</h3>
                  <div dangerouslySetInnerHTML={{ __html: blog_first.short_description}}>
                  </div>
                </div>
                <p>

                  {/* {JSON.stringify(blog_first.category.split(','))} */}
                </p>
                {/* blog tags */}
                <div className="blog-tags mt-0 text-dark mb-4">
                  {(blog_first.category) != undefined  ?
                  
                  (blog_first.category.split(',')).map((prop, key) => {
                    return (
                      <a href="#"><i className="fa fa-tag text-dark" />{prop}</a>
                    );
                  })
                :
                null
                  }
                
                </div>
                <div className="read-more mt-0">
                  
                  <p><a onClick={()=> go_to_single(blog_first.slug)} style={{cursor : 'pointer'}} className="btn btn-outline-black ">{t('readmore')}</a></p>
                </div>
                {/* blog tags */}
              </div>
                :
                null

              }

              <div className="blog-more">
                <div className="row">
                {(blog) != undefined ? 
                (blog).map((prop, key) => {
                      if(prop.isFirst != 1) {

                        return (
  
                    <div className="blog">
                      <div className="blog-content">
                        <a onClick={()=> go_to_single(prop.slug)} style={{cursor : 'pointer'}}>
                          <div className="blog-img col-md-4">
                            <img className="img-responsive w-100" src={base_url + prop.banners.split(',')[0]} alt="" />
                          </div>
                        </a>
                        <div className="col-md-8">
                          <ul className="blog-meta">
  
                            
                            {/* <li><i className="fa fa-user" />John doe</li> */}
                            <li><i className="fa fa-clock-o" />{prop.created_at}</li>
                          </ul>
                          <h3>{prop.title}</h3>
                          <div dangerouslySetInnerHTML={{ __html: prop.short_description}}>
                          </div>
                          <div className="blog-tags mt-0 text-dark mb-4">
                            {(prop.category.split(',')).map((prop, key) => {
                                return (
                                  <a href="#"><i className="fa fa-tag text-dark" />{prop}</a>
                                );
                              })}
                          </div>
                          
                          <div className="read-more mt-0">
                            <p><a onClick={()=> go_to_single(prop.slug)} style={{cursor : 'pointer'}} className="btn btn-outline-black ">{t('readmore')}</a></p>
                          </div>
                        </div>
                      </div>
                     
                    </div>
                     );
                      }                
                  })
                
                :
                null
              }

                  </div>
 
                </div>
                <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pagecount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
        />
            </main>
            {/* /Main */}
            {/* Aside */}
            <aside id="aside" className="col-md-3">
              {/* Search */}
              <div className="widget">
                <div className="widget-search">
                  <input className="search-input" type="text" placeholder="search"  onChange={e => handleInputChange(e.target.value)}  />
                  <button className="search-btn" type="button"><i className="fa fa-search" /></button>
                </div>
              </div>
              {/* /Search */}
              {/* Category */}
              <div className="widget">

                <h3 className="title">{t('category')}</h3>
                <div className="widget-category">
                <a style={{cursor : 'pointer','fontWeight' : 'bolder'}} onClick={()=> set_array_category([])}>Show All Blog<span style={{cursor : 'pointer'}}></span>
                      </a>
                {(category).map((prop, key) => {
                    return (
                      <a style={{cursor : 'pointer'}} onClick={()=> handleArrayClick(prop.category_id)}>{prop.title}<span style={{cursor : 'pointer'}} onClick={()=> removeArrray(prop.category_id)}>x</span>
                      </a>
                    );
                  })}
                  {/* <a href="#">Marketing<span>(142)</span></a>
                  <a href="#">Web Development<span>(74)</span></a>
                  <a href="#">Branding<span>(60)</span></a>
                  <a href="#">Photography<span>(5)</span></a> */}
                </div>
              </div>
              {/* /Category */}
              {/* Posts sidebar */}
              <div className="widget">
                <h3 className="title">{t('popularpost')}</h3>
                {/* single post */}
                {(blog_popular).map((prop, key) => {
                    return (

                <div className="widget-post">
                  <a onClick={()=> go_to_single(prop.slug)} style={{cursor : 'pointer'}}>
                    <img src={base_url + prop.banners.split(',')[0]} style={{width:'85px'}} alt="" />{prop.title}
                  </a>
                  <ul className="blog-meta">
                    <li>{prop.created_at}</li>
                  </ul>
                </div>
                   );
                  })}
               
              </div>
              {/* /Posts sidebar */}
              {/* Tags */}
              <div className="widget d-none">
                <h3 className="title">Tags</h3>
                <div className="widget-tags">
                  <a href="#">Web</a>
                  <a href="#">Design</a>
                  <a href="#">Graphic</a>
                  <a href="#">Marketing</a>
                  <a href="#">Development</a>
                  <a href="#">Branding</a>
                  <a href="#">Photography</a>
                </div>
              </div>
              {/* /Tags */}
            </aside>
            
            {/* /Aside */}
          </div>
          {/* /Row */}
        </div>
        {/* /Container */}
      </div>
      {/* /Blog */}
      {/* Footer */}
    

<Footer/>
      {/* /Footer */}
      {/* Back to top */}
      <div id="back-to-top" />
      {/* /Back to top */}
      {/* Preloader */}
      {/* <div id="preloader">
        <div className="preloader">
          <span />
          <span />
          <span />
          <span />
        </div>
      </div> */}
    </div>

    );
}
