import React, { useState , useEffect,useContext } from "react";
import Header from './Header'

import {get_portfolio,get_base_url} from './API/api';
import {
  BrowserRouter as Router,
  Routes,
  useNavigate,
  Route,
  Link,
  useParams,
} from 'react-router-dom';
import ContactForm from "./ContactForm";
import Footer from './Footer'
import { LanguageContext } from './languagecontext'

import { useTranslation } from 'react-i18next'


export default function Portfolio() {
  const { t, i18n } = useTranslation()

  const [base_url , set_base_url ] = useState("")
  const [portfolio,set_portfolio] = useState([])
  const navigate = useNavigate();
  const {language_selected} = useContext(LanguageContext);



  const get_data = () => {

    get_portfolio({language_id : language_selected}).then(portfolio_data => {
      // alert(JSON.stringify(portfolio_data))
      set_portfolio(portfolio_data)
    });
  
    get_base_url({language_id : language_selected}).then(base_url => {
      set_base_url(base_url)  
    })

 }
 function go_to_single(slug){

  navigate('/portfolio/' + slug, { state: { data : portfolio.find(arr=>arr.slug == slug) , base_url : base_url} });

 }

 useEffect(()=>{
    get_data()
 },[language_selected])
    return (
        <div>
        <header id="home" style={{height: '60vh'}}>
          {/* Background Image */}
          <div className="bg-img" style={{backgroundImage: 'url("./img/background1.jpg")'}}>
            <div className="overlay" />
          </div>
          {/* /Background Image */}
          {/* Nav */}
          <Header></Header>

          {/* /Nav */}
          {/* home wrapper */}
          <div className="home-wrapper">
            <div className="container">
              <div className="row">
                {/* home content */}
                <div className="col-md-10 col-md-offset-1">
                  <div className="home-content">
                    <h1 className="white-text">{t('ourwork')}</h1>
                    {/* <p className="white-text">Morbi mattis felis at nunc. Duis viverra diam non justo. In nisl. Nullam sit amet magna in magna gravida vehicula. Mauris tincidunt sem sed arcu. Nunc posuere.
                    </p> */}
                    <a className="main-btn" href="/portfolio#portfolio_section">{t('getstarted')}</a>
                  </div>
                </div>
                {/* /home content */}
              </div>
            </div>
          </div>
          {/* /home wrapper */}
        </header>
        {/* /Header */}
        {/* About */}
        {Object.keys(portfolio).length > 0 ? 
        <div className="section bg-grey md-padding" id="portfolio_section">
          <div className="container">
            <div className="row mb-5">
              <div className="col-12 text-center">
                <h2 className="mb-4 section-title">{t('lastestwork')}</h2>
              </div>
            </div>
            <div className="row" style={{  padding : '0 4px'}}>
            <div class="column">
            {Object.keys(portfolio).length > 0 ? 

              (portfolio).map((prop, key) => {
                
                return (
                
                  prop.weight < 0 ? 
                
                <a onClick={()=> go_to_single(prop.slug)} style={{cursor : 'pointer'}} className="work-thumb">

                    <div className="work-text">
                    <h2>{prop.title} 
                    </h2>
                    <p>{prop.category}</p>
                  </div>
                  <img src={base_url + prop.banner} alt="Image" className="img-fluid" />
                </a>
                :
                  null
              );
              
            })
            
            :
            null
          }
          </div>
          <div class="column">
            {Object.keys(portfolio).length > 0 ? 

              (portfolio).map((prop, key) => {
                return (
                  prop.weight > 0 ? 
                
                  <a onClick={()=> go_to_single(prop.slug)} style={{cursor : 'pointer'}} className="work-thumb">
  
                      <div className="work-text">
                      <h2>{prop.title} 
                      </h2>
                      <p>{prop.category}</p>
                    </div>
                    <img src={base_url + prop.banner} alt="Image" className="img-fluid" />
                  </a>
                  :
                    null
              );
            })
            
            :
            null
          }
          </div>

          
              {/* <div className="col-lg-6">
                <a href="portfolio-single.html" className="work-thumb">
                  <div className="work-text">
                    <h2>Work Name Here</h2>
                    <p>Category Here</p>
                  </div>
                  <img src="images/work_2.jpg" alt="Image" className="img-fluid" />
                </a>
                <a href="portfolio-single.html" className="work-thumb">
                  <div className="work-text">
                    <h2>Work Name Here</h2>
                    <p>Category Here</p>
                  </div>
                  <img src="images/work_3.jpg" alt="Image" className="img-fluid" />
                </a>
              </div> */}

            </div>
            <div className="row mt-5">
              <div className="col-12 text-center">
                <p><a href="portfolio.html" className="btn btn-outline-white px-4 py-3">More Portfolio</a></p>
              </div>
            </div>
          </div>
        </div>
                      
            :
            null}
          {/* <ContactForm/> */}

        <Footer/>
        {/* /Footer */}
        {/* Back to top */}
        <div id="back-to-top" />
        {/* /Back to top */}
        {/* Preloader */}
        {/* <div id="preloader">
          <div className="preloader">
            <span />
            <span />
            <span />
            <span />
          </div>
        </div> */}
      </div>

    );
}
