


// const host = "http://localhost/piegenwebsite/";
const host = "https://piegendevelop.com/piegenwebsite/";
const access_endpoint_link = host + "API/";

const format_request = (postparam) => {

    // postparam['user_token'] = window.localStorage.getItem("user_token");
    // postparam['user_id'] = window.localStorage.getItem("user_id");
    // postparam['role'] = window.localStorage.getItem("role");

    // AsyncStorage.getItem('user_location').then(user_location => {
    // //     console.log('user location XXXXXnpXXXXXXXXXXXXXXXXXXX');
    // //     console.log(user_location);
    //     postparam['user_location'] = user_location;

        let body = '';
        for (let k in postparam) {
            body += encodeURI(k) + "=" + encodeURI(postparam[k]) + "&";
        }
        console.log(body);
        var postOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: body
        };
        return postOptions;
    // });
}

async function return_response(response,json){
    if(response.ok){
        return json.data;
    }else{
        alert(json.error);
    }
}

export const test = async (postparam) => {


    var response = await fetch("https://nodelanding.herokuapp.com/json", format_request(postparam));
    var json = await response.json();
    //console.log(json);
     return json;
  
}

export const get_banner = async (postparam) => {


    var response = await fetch(access_endpoint_link + "get_banner", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
  
}

export const get_blog_category_by_blog_id = async (postparam) => {


    var response = await fetch(access_endpoint_link + "get_blog_category_by_blog_id", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
  
}

export const get_language = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_language", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    // alert(JSON.stringify(json))
    return return_response(response,json);
  
}

export const get_about = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_about", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
}


export const get_portfolio = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_portfolio", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
}
export const get_testimonia = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_testimonia", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
}
export const get_service = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_service", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
}
export const get_feature = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_feature", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
}
export const get_blog = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_blog", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return json;
    // return return_response(response,json);
}

export const get_category = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_category", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
    
}


export const get_base_url = async (postparam) => {

    var response = await fetch(access_endpoint_link + "get_base_url", format_request(postparam));
    var json = await response.json();
    //console.log(json);
    return return_response(response,json);
}