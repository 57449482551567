import React, { useState , useEffect } from "react";
// import './js/jquery.magnific-popup'
import {
  BrowserRouter as Router,
  Link,
} from 'react-router-dom';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle'
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { useTranslation } from 'react-i18next'

export default function Footer() {
  const { t, i18n } = useTranslation()

    return (

<footer>
            <div className="container">
              <div className="row">
                <div className="col-md-2 col-sm-12">
                  <div className="logo">
                    <h1 className="mb-0 site-logon text-white h6 mb-0"><img src="https://piegensoftware.com/img/logo.png" alt="logo" style={{width: '30px'}} />
                      PieGen Software 
                    </h1>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 text-white">
                  <div className="lokasi" data-nosnippet>
                    <h4>{t('location')}</h4>
                    <p>121-01, Jalan Mutiara Emas 2A, Taman Mount Austin, 81100 Johor Bahru, Johor</p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 text-white">
                  <div className="contact-info" data-nosnippet>
                    <h4>{t('moreinfo')}</h4>
                    <p>{t('phone')}: 016-7889773 (Kenneth Wong)</p>
                    {/* <p>{t('phone')} : 016-7488035 (Kelvin Keo)</p> */}
                    <p>{t('email')} : Kwongwf17@outlook.com</p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12">
                  <div className="kasicall text-center">
                    <h4>{t('socialwithus')}</h4>
                    <ul style={{margin: 0, padding: 0, textAlign: 'center', listStyle: 'none'}}>
                      <li><a href="#"><i className="fa fa-whatsapp" /></a></li> 
                      <li><a href="https://www.instagram.com/piegen_software/"><i className="fa fa-instagram" /></a></li>
                      <li><a href="https://www.facebook.com/PieGen-Software-111184540529485/?ref=bookmarks"><i className="fa fa-facebook" /></a></li>
                      <li><a href="#"><i className="fa fa-google" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </footer>
 )
}