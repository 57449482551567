import React, { useState , useEffect,useContext } from "react";
// import './js/jquery.magnific-popup'
import {
  BrowserRouter as Router,
  Link,
} from 'react-router-dom';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle'
// import 'bootstrap/dist/css/bootstrap.min.css';
// import {pwa} from './API/pwa';
import 'bootstrap/dist/js/bootstrap.min.js';

import { useTranslation } from 'react-i18next'
import {get_base_url,get_language} from './API/api';
import { LanguageContext } from './languagecontext'

export default function Header() {
  const { t, i18n } = useTranslation()
  const {set_language_selected,language_selected} = useContext(LanguageContext);
  const [base_url_home,set_base_url_home]  = useState("");
  const changeLanguage = (value,language_id) => {
    // alert(value)
    i18n.changeLanguage(value)
    localStorage.setItem('language_id',language_id);
    set_language_selected(language_id)
  }
  const [language , set_language ] = useState([])


  useEffect(()=>{
    get_language({}).then(language_data => {
      set_language(language_data)
    
    });

    get_base_url({}).then(base_url => {
      set_base_url_home(base_url)
    });    ///////////////////////////
    // Smooth scroll
    $("#nav .main-nav a[href^='#']").on('click', function(e) {
      // alert("asdasdasd");
      e.preventDefault();
      var hash = this.hash;
      $('html, body').animate({
        scrollTop: $(this.hash).offset().top
      }, 600);
    });
    ///////////////////////////
    // Btn nav collapse
    $('#nav .nav-collapse').on('click', function() {
      $('#nav').toggleClass('open');
    });
  
    changeLanguage('en',2);
  },[])
  
  const baseurl = "https://piegensoftware.com/Piegen/";
    return (
<nav id="nav" className="navbar nav-transparent">
            <div className="container d-block">

              <div className="navbar-header">
                <div className="navbar-brand">
                <Link to={ "/"}>

                    <img className="logo" src="https://piegensoftware.com/img/logo.png" alt="logo" />
                    <img className="logo-alt" src="https://piegensoftware.com/img/logo.png" alt="logo" />
                </Link>

                </div>
                <div className="nav-collapse">
                  <span />
                </div>
              </div>
              <ul className="main-nav nav navbar-nav navbar-right d-inline">
                <li><Link to={ "/"}>{t('home')}</Link></li>
                <li><Link to="/about">{t('about')}</Link></li>
                <li><Link to="/portfolio">{t('portfolio')}</Link></li>
                <li><Link to="/service">{t('service')}</Link></li>
                <li><Link to="/blog">{t('blog')}</Link>
                
                </li>
                {/* <li  className="has-dropdown"><a style={{cursor : 'pointer'}}>{t('language')}</a>
                <ul className="dropdown">
                   
                    {(language).map((prop, key) => {
                    return (
                      <li style={{color:'white',cursor:'pointer',textTransform : 'capitalize'}} onClick={() => changeLanguage(prop.abbr,prop.language_id)}>{prop.language}</li>
                      );
                  })}

                  </ul>
                </li> */}
              </ul>
         
            </div>
          </nav>

    )
}