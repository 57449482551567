import React, { useState , useEffect,useContext } from "react";
import Header from './Header'
import {
  BrowserRouter as Router,
  Routes,
  useNavigate,
  Route,
  Link,
  useParams,
  useLocation,
} from 'react-router-dom';
import ContactForm from "./ContactForm";
import Footer from './Footer'
import { useTranslation } from 'react-i18next'
import { LanguageContext } from './languagecontext'

export default function PortfolioSingle({...props}) {
  // const { data } = props.location
  const {state} = useLocation();
  // alert(JSON.stringify(state));
  const { t, i18n } = useTranslation()
  const {language_selected} = useContext(LanguageContext);

    return (
    
        <div>
        {/* Header */}
        <header id="home" style={{height: '60vh'}}>
          {/* Background Image */}
          <div className="bg-img" style={{backgroundImage: 'url("../img/background1.jpg")'}}>
            <div className="overlay" />

          </div>
          {/* /Background Image */}
          {/* Nav */}
          <Header></Header>

          {/* /Nav */}
          {/* home wrapper */}
          <div className="home-wrapper">
            <div className="container">
              <div className="row">
                {/* home content */}
                <div className="col-md-10 col-md-offset-1">
                  <div className="home-content">
                    <h1 className="white-text">{t('ourwork')}</h1>
                    {/* <p className="white-text">Morbi mattis felis at nunc. Duis viverra diam non justo. In nisl. Nullam sit amet magna in magna gravida vehicula. Mauris tincidunt sem sed arcu. Nunc posuere.
                    </p> */}
                    {/* <a className="main-btn" href={"/portfolio/" + state.data.slug + "#single_section"}>{t('getstarted')}</a> */}
                  </div>
                </div>
                {/* /home content */}
              </div>
            </div>
          </div>
          {/* /home wrapper */}
        </header>
        {/* /Header */}
        {/* About */}
        <div className="section md-padding" id="single_section">
          <div className="container">
            <div className="row mb-5">
              <div className="col-lg-6">
                <img src={state.base_url +state.data.banner} alt="Image" className="img-fluid" />
              </div>
              <div className="col-lg-5 ml-auto">
                <h3 className="mb-4">{state.data.title}</h3>
                <p>{state.data.description}</p>
                <ul className="list-unstyled mb-5">
                  <li className="mb-3">
                    <strong className="d-block text-black">{t('category')} :</strong>
                    {state.data.category}
                  </li>
                  <li>
                    <strong className="d-block text-black">{t('Client')}:</strong>
                    {state.data.client}
                  </li>
                </ul>
                <p><a href={state.data.website_link} target="_blank" className="btn btn-outline-black ">{t('VisitWebsite')}</a></p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                {/* <p><a href="#" className="btn btn-outline-black btn-block">{t('previosproject')}</a></p> */}
              </div>
              <div className="col-lg-4">
                <p>
                <Link
                  className="btn btn-outline-black btn-block"
                    to={{
                      pathname: "/portfolio",
                    }}
                  >
                    {t('allproject')}
                    </Link>
                  </p>
              </div>
              <div className="col-lg-4">
                {/* <p><a href="#" className="btn btn-outline-black btn-block">{t('nextproject')}</a></p> */}
              </div>
            </div>
          </div>
        </div>
        <ContactForm/>
          <Footer/>
        {/* /Footer */}
        {/* Back to top */}
        <div id="back-to-top" />
        {/* /Back to top */}
        {/* Preloader */}
        {/* <div id="preloader">
          <div className="preloader">
            <span />
            <span />
            <span />
            <span />
          </div>
        </div> */}
      </div>
    );
}
