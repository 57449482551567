import React, { useState , useEffect,useContext } from "react";
import Header from './Header'
import {
  BrowserRouter as Router,
  Routes,
  useNavigate,
  Route,
  Link,
  useParams,
  useLocation,

} from 'react-router-dom';
import Footer from './Footer'
import { useTranslation } from 'react-i18next'
import {get_blog,get_base_url,get_blog_category_by_blog_id,get_category} from './API/api';
import ReactPaginate from 'react-paginate';
import { LanguageContext } from './languagecontext'
import Blog from './Blog'

export default function BlogSingle({...props}) {
    // const { data } = props.location
    // const {state} = useLocation();
    const { slug } = useParams()
    const { t, i18n } = useTranslation()
    const {language_selected} = useContext(LanguageContext);

    const [blog,set_blog] = useState([])
    const [category,set_category] = useState([])
    const [blog_category,set_blog_category] = useState([])

    const [offset,setoffset ] = useState(0)
    const [limit,setLimit] = useState(4)
    const [base_url , set_base_url ] = useState("")
  
    const [blog_popular,set_blog_popular] = useState([])
  
    const navigate = useNavigate();

    const get_data = () => {
   
      get_base_url({language_id : language_selected}).then(base_url => {
        set_base_url(base_url)  

      })
      get_category({language_id : language_selected}).then(category => {
        set_category(category)  
      })



      
   }

   function go_to_single(slug){
  
    navigate('/blog/' + slug);
  
   }


   function go_to_blog_category_id(category_id){

    navigate('/blog', { state: { category_id : category_id , base_url : base_url} });
  
   }
 
  useEffect(()=>{
    get_data()

  },[])
   useEffect(()=>{
      
      get_blog({language_id : language_selected,offset : offset , limit : 1000}).then(blog_data => {
        // alert(blog_data.total_count)
        // alert(JSON.stringify(blog_data))
        if((blog_data.data) != undefined ){

          set_blog((blog_data.data).find(arr=>arr.slug == slug))

          get_blog_category_by_blog_id({language_id : language_selected,blog_id : (blog_data.data).find(arr=>arr.slug == slug).blog_id}).then(blog_category => {
            set_blog_category(blog_category)  
          })

          // alert(JSON.stringify((blog_data.data).find(arr=>arr.slug == slug)))
          let popular_post = []
          for (let index = 0; index < (blog_data.data).length; index++) {
            const element = (blog_data.data)[index];
            popular_post.push(element);
          }
          set_blog_popular(popular_post)

        }else{
          set_blog([])

        }
      
        // alert(JSON.stringify(blog_data.find(arr=>arr.isFirst == "1")))
      });
    
   },[offset,slug,language_selected])
  
    return (
        <div>
        <header>
          {/* Nav */}
          <Header></Header>

          {/* /Nav */}
          {/* header wrapper */}
          <div className="bg-img" style={{backgroundImage: 'url("../img/background1.jpg")'}}>
          <div className="overlay" />

        </div>
          <div className="header-wrapper sm-padding bg-grey">
            <div className="container">
              <h2>{blog.title}</h2>
              <ul className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">{t('home')}</Link></li>
                <li className="breadcrumb-item"><Link to="/blog">{t('blog')}</Link></li>
                <li className="breadcrumb-item active">{blog.title}</li>
              </ul>
            </div>
          </div>
          {/* /header wrapper */}
        </header>
        {/* /Header */}
        {/* Blog */}
        <div id="blog" className="section md-padding">
          {/* Container */}
          <div className="container">
            {/* Row */}
            <div className="row">
              {/* Main */}
              <main id="main" className="col-md-9">
                <div className="blog">

                  <div className="blog-img">
                    <img className="img-responsive" src={base_url +blog.banners} alt="" />
                  </div>
                  <div className="blog-content">
                    <ul className="blog-meta">
                      <li><i className="fa fa-clock-o" />{blog.created_at}</li>
                    </ul>
                    <h3>{blog.title}</h3>
                    <div dangerouslySetInnerHTML={{ __html: blog.description}}>
                  </div>
                  </div>
                  {/* blog tags */}
                  <div className="blog-tags">
                    <h5>{t('category')} :</h5>
                    {(blog_category).map((prop, key) => {
                    return (
                      <a onClick={()=> go_to_blog_category_id(prop.category_id)}><i className="fa fa-tag" />{prop.title}</a>
                      );
                  })}
                    {/* <a href="#"><i className="fa fa-tag" />Web</a>
                    <a href="#"><i className="fa fa-tag" />Design</a>
                    <a href="#"><i className="fa fa-tag" />Marketing</a>
                    <a href="#"><i className="fa fa-tag" />Development</a>
                    <a href="#"><i className="fa fa-tag" />Branding</a>
                    <a href="#"><i className="fa fa-tag" />Photography</a> */}
                  </div>
                  {/* blog tags */}
                  {/* blog author */}
                  <div className="blog-author">
                    <div className="media">
                      <div className="media-left">
                        <img className="media-object" src="./img/author.jpg" alt="" />
                      </div>
                      <div className="media-body">
                        <div className="media-heading">
                          <h3>Piegen Software</h3>
                          <div className="author-social">
                            <a href="https://www.facebook.com/PieGen-Software-111184540529485/"><i className="fa fa-facebook" /></a>
                            {/* <a href="#"><i className="fa fa-twitter" /></a>
                            <a href="#"><i className="fa fa-google-plus" /></a> */}
                            <a href="https://www.instagram.com/piegen_software/?hl=ja"><i className="fa fa-instagram" /></a>
                          </div>
                        </div>
                        {/* <p>Nec feugiat nisl pretium fusce id velit ut tortor pretium. Nisl purus in mollis nunc sed. Nunc non blandit massa enim nec.</p> */}
                      </div>
                    </div>
                  </div>
                  {/* /blog author */}
                  {/* blog comments */}
                  {/* /reply form */}
                </div>
              </main>
              {/* /Main */}
              {/* Aside */}
              <aside id="aside" className="col-md-3">
                {/* Search */}
                {/* <div className="widget">
                  <div className="widget-search">
                    <input className="search-input" type="text" placeholder="search" />
                    <button className="search-btn" type="button"><i className="fa fa-search" /></button>
                  </div>
                </div> */}
                {/* /Search */}
                {/* Category */}
                <div className="widget">
                  <h3 className="title">{t('category')}</h3>
                  <div className="widget-category">
                  {(category).map((prop, key) => {
                    return (
                      <a style={{cursor : 'zoom-in'}} onClick={()=> go_to_blog_category_id(prop.category_id)}>{prop.title}<span>({prop.total_count})</span></a>
                      );
                  })}
                  
                  </div>
                </div>
                {/* /Category */}
                {/* Posts sidebar */}
                <div className="widget">
                  <h3 className="title">Populare Posts</h3>
                  {(blog_popular).map((prop, key) => {
                    return (

                <div className="widget-post">
                  <a style={{cursor : 'zoom-in'}} onClick={()=> go_to_single(prop.slug)} style={{cursor : 'pointer'}}>
                    <img src={base_url + prop.banners.split(',')[0]} style={{width:'85px'}} alt="" />{prop.title}
                  </a>
                  <ul className="blog-meta">
                    <li>{prop.created_at}</li>
                  </ul>
                </div>
                   );
                  })}
                 
                </div>
                {/* /Posts sidebar */}
                {/* Tags */}
                <div className="widget d-none">
                  <h3 className="title">Tags</h3>
                  <div className="widget-tags">
                    <a href="#">Web</a>
                    <a href="#">Design</a>
                    <a href="#">Graphic</a>
                    <a href="#">Marketing</a>
                    <a href="#">Development</a>
                    <a href="#">Branding</a>
                    <a href="#">Photography</a>
                  </div>
                </div>
                {/* /Tags */}
              </aside>
              {/* /Aside */}
            </div>
            {/* /Row */}
          </div>
          {/* /Container */}
        </div>
        {/* /Blog */}
        {/* Footer */}

<Footer/>
        {/* /Footer */}
        {/* Back to top */}
        <div id="back-to-top" />
        {/* /Back to top */}
        {/* Preloader */}
        {/* <div id="preloader">
          <div className="preloader">
            <span />
            <span />
            <span />
            <span />
          </div>
        </div> */}
      </div>

    );
}
