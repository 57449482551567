
import React, { useState , useEffect,useContext } from "react";
import $ from 'jquery';
// import './js/jquery.magnific-popup'
// import OwlCarousel from 'react-owl-carousel2';
// import 'react-owl-carousel2/style.css'; //Allows for server-side render

import OwlCarousel, { Options }  from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import {

  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
} from 'react-router-dom';
import Header from './Header'
import Footer from './Footer'

import fancybox from "@fancyapps/fancybox";
import './fancybox.css'
import 'bootstrap/dist/js/bootstrap.bundle'
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "magnific-popup";
import {get_banner ,test,get_about,get_feature,get_base_url,get_service,get_testimonia} from './API/api';
import ContactForm from "./ContactForm";
import { useTranslation } from 'react-i18next'

import { LanguageContext } from './languagecontext'



export default function Home() {

  const {language_selected} = useContext(LanguageContext);

   const [banner,set_banner] = useState([])
   const [about_section_one , set_about_section_one ] = useState([])
   const [about_section_two , set_about_section_two ] = useState([])
   const [about_section_three , set_about_section_three ] = useState([])
   const [about_section_four , set_about_section_four ] = useState([])
   const [feature , set_feature ] = useState([])
   const [service , set_service ] = useState([])
   const [testimonia , set_testimonia ] = useState([])
   const { t, i18n } = useTranslation()

   const  options = {
   
          0: {
              items: 1,
          },
          600: {
              items: 2,
          },
          1000: {
              items: 3,
          },
  }
   const [base_url , set_base_url ] = useState("")

   const get_data = () => {
    var language_id = localStorage.getItem("language_id");

      get_banner({language_id : language_selected}).then(banner_data => {

        set_banner(banner_data)
      });
      // test({language_id : language_selected}).then(test => {
      //   alert(JSON.stringify(test))
      // });
      get_about({language_id : language_selected}).then(about_data => {
        // alert(JSON.stringify(about_data[0]))
        set_about_section_one(about_data.find(arr=>arr.type == "homesectionone"));
        set_about_section_two(about_data.find(arr=>arr.type == "homesectiontwo"));
        set_about_section_three(about_data.find(arr=>arr.type == "homesectionthree"));
        set_about_section_four(about_data.find(arr=>arr.type == "homesectionfour"));
      });
      get_feature({language_id : language_selected}).then(feature_data => {
        set_feature(feature_data);
   
      });
      get_base_url({language_id : language_selected}).then(base_url => {
        set_base_url(base_url)
      })
      get_testimonia({language_id : language_selected}).then(testimonia_data => {
        set_testimonia(testimonia_data)
      })
      get_service({language_id : language_selected,isHome : 1 , isFirst : 0}).then(service_data => {
        set_service(service_data)
        
      })
      

    
      

   }

   useEffect(()=>{
      get_data()
   },[language_selected])
  

    return (
    <div>
       {Object.keys(banner).length > 0 ? 
       
        <header id="home">
          <div className="bg-img" style={{backgroundImage: 'url("'+banner.banner+'")'}}>
            <div className="overlay" />
          </div>
          <Header></Header>
          
          <div className="home-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-10 col-md-offset-1">
                  <div className="home-content">
                    <h1 className="white-text" dangerouslySetInnerHTML={{ __html: banner.title}}>
                    </h1>
                    
                    <p className="white-text">{banner.description}
                    </p>
                    <Link to="/about" className="white-btn">{banner.button_one}</Link>
                    <Link to="/service" className="main-btn">{banner.button_two}</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      :
      null
       }
        {/* <style dangerouslySetInnerHTML={{__html: "\n\n\n\n\n\t" }} />
        <video width={640} height={320} controls id="myVideo" style={{display: 'none'}}>
          <source src="https://www.html5rocks.com/en/tutorials/video/basics/Chrome_ImF.mp4" type="video/mp4" />
          <source src="https://www.html5rocks.com/en/tutorials/video/basics/Chrome_ImF.webm" type="video/webm" />
          <source src="https://www.html5rocks.com/en/tutorials/video/basics/Chrome_ImF.ogv" type="video/ogg" />
          Your browser doesn't support HTML5 video tag.
        </video> */}
        {Object.keys(about_section_one).length > 0 ? 

        <div className="section md-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 mr-auto mb-5">
              <img className="img-responsive" style={{height: '100%'}} src={about_section_one.banner} alt="" />
              </div>
              <div className="col-lg-6">
                <span className="d-block text-uppercase text-secondary">{about_section_one.title}</span>
                <span className="divider my-4" />
               
                <h2 className="mb-4 section-title" >
                <div dangerouslySetInnerHTML={{ __html: about_section_one.description}}>

                </div>
                </h2>
                <p className="mb-5" dangerouslySetInnerHTML={{ __html: about_section_one.short_description}}></p>
                <p>
                <Link to="/about" className="btn btn-outline-black" dangerouslySetInnerHTML={{ __html: about_section_one.button_text}}  ></Link>
                  </p>
              </div>
            </div>
          </div>
        </div>
        :
        null
        }
        {Object.keys(about_section_two).length > 0 ? 

        <div id="about">
          <div className="col-md-12 d-flex justify-content-center bg-dark" style={{marginBottom: '100px'}}>
            <div className="site-section text-center bg-dark" style={{padding: '70px 0px 70px',width:'100%  '}}>
              <div className="col-lg-12 m-auto pl-lg-5 text-center text-white bg-dark" style={{fontFamily: 'sans-serif"', fontSize: '4em'}}>
                <blockquote style={{alignItems: 'center',margin:'auto'}}>
                  <h2 className="mb-4 section-title text-white">
                  {about_section_two.title} <strong>{about_section_two.short_description}</strong>
                  </h2></blockquote>
                <p className="d-block" style={{fontSize: '3rem', color: 'white', textAlign: 'center'}}  dangerouslySetInnerHTML={{ __html: about_section_two.description}}>
                </p>
              </div>
            </div>
          </div>
        </div>
        :
        null  
        }

      {Object.keys(about_section_three).length > 0 ? 

        <div id="features" className="md-padding bg-grey">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="section-header">
                  <h2 className="title">{about_section_three.title}</h2>
                </div>
                {Object.keys(about_section_three).length > 0 ? 

                (about_section_three.list).map((prop, key) => {
                  return (

                  <div className="feature" key={key}>
                    <i className="fa fa-check" />
               
                    <div dangerouslySetInnerHTML={{ __html: prop}}>

                    </div>
                  </div>
                  );
                })
              
              :

              null
              }
               
              </div>
              <div className="col-md-6">
              <OwlCarousel className='owl-carousel owl-theme' options={options} nav loop dots autoplay items={
                1

              }>
              {Object.keys(about_section_three).length > 0 ? 

              (about_section_three.banner).map((prop, key) => {
                return (
                  <div className='item' key={key}>
                      <img className="img-responsive" src={about_section_three.base_url + prop} alt="" />

                  </div>

                );
                })
                :
                null
                }
            
    
             </OwlCarousel>

             
              </div>
            </div>
          </div>
        </div>
        :
          null
        }
        {Object.keys(about_section_four).length > 0 ? 

        <div id="videosection">
          <div className="slider-item" data-stellar-background-ratio="0.5" style={{backgroundImage: 'url("'+ about_section_four.banner+'")'}}>
            <div className="container">
              <div className="row slider-text align-items-center justify-content-center">
                <div className="col-lg-9 text-center col-sm-12">
                  <h1 className="mb-4">{about_section_four.title}</h1>
                  <div className="btn-play-wrap mx-auto">
                  {/* <a href="https://source.unsplash.com/0JYgd2QuMfw/1500x1000" data-fancybox data-caption="This image has a simple caption">
    <img src="https://source.unsplash.com/0JYgd2QuMfw/240x160" />
  </a> */}
                    <p className="mb-4"><a href={about_section_four.video_link} data-fancybox data-ratio="2" className="btn-play"><span className="fa fa-play text-dark text-center" /></a></p>
                  </div>
                  <span>{about_section_four.short_description}</span>
                </div>

              </div>
            </div>
          </div>
        </div>


        :
        null
        }
        {Object.keys(feature).length > 0 ? 

        
        <div id="portfolio" className="section md-padding bg-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-header text-center">
                  <h2 className="title">{t('featuredproject')}</h2>
                </div>
              </div>
              
              {
                (feature).map((prop, key) => {
                  return (
                    <div className="col-md-4 col-xs-6 work"  key={key}>
                    <img className="img-responsive" src={base_url + prop.banner} alt="" />
                    {/* <div className="overlay" /> */}
                    <div className="work-content">
                      
                      <span>{prop.title}</span>
                      <h3>{prop.description}</h3>
                      <div className="work-link">
                        <a href={prop.link}  target="_blank"><i className="fa fa-external-link" /></a>
                        {/* <a className="lightbox" data-fancybox data-ratio="2" href={base_url + prop.banner}><i className="fa fa-search" /></a> */}
                      </div>
                    </div>
                  </div> 
  
                  );
                  })
              }
              
            </div>
          </div>
        </div>

            :
            null
            }

{Object.keys(service).length > 0 ? 

        <div id="service" className="section md-padding bg-grey">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-header text-center">
                  <h2 className="title">{t('offer')}</h2>
                </div>
              </div>
              
              {
                (service).map((prop, key) => {
                  return (
              <div className="col-md-4 col-sm-6">
                <div className="service">
                  <i className={prop.logo} />
                  <h3>{prop.title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: prop.description}}></p>
               
                </div>
              </div>
                 );
                })
            }
              
            </div>
          </div>
        </div>
         :
         null
         }

         {Object.keys(testimonia).length > 0 ? 

        <div id="pricing" className="section md-padding bg-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12">	
                <div className="section-header text-center">
                  <h2 className="title">{t('notableclient')}</h2>
                </div>
              </div>
              <OwlCarousel className='owl-carousel owl-theme' loop nav responsiveClass={true} responsive={options}>
              {
                (testimonia).map((prop, key) => {
                  return (
                <div className="pricing">
                  <div className="price-head">
                    <span className="price-title">{prop.title}</span>
                    <div className="price">
                    <img src={base_url + prop.banner} className="rounded-circle w-100 position-absolute" style={{left: '0px'}} alt="" />
                    </div>
                  </div>
                  <ul className="price-content">
                    <p>{prop.description}</p>
                  </ul>
                  <div className="price-btn">
                    <a className="outline-btn" target="_blank" href={prop.website_link}>{t('viewproject')}</a>
                  </div>
                </div>
                    );
                  })
              }
              
                </OwlCarousel>
            </div>
          </div>
          </div>

          :
         null
         }
          <ContactForm/>

          <Footer/>
          <div id="back-to-top" />
          {/* <div id="preloader">
            <div className="preloader">
              <span />
              <span />
              <span />
              <span />
            </div>
          </div> */}
        </div>

    );
}
