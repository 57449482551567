import React, { useState , useEffect,useContext } from "react";
import Header from './Header'
import './about.css'
import {get_about,get_base_url} from './API/api';
import ContactForm from "./ContactForm";
import Footer from './Footer'
import { useTranslation } from 'react-i18next'
import { LanguageContext } from './languagecontext'

export default function About() {
  const { t, i18n } = useTranslation()
  const {language_selected} = useContext(LanguageContext);

  const [about_section_one , set_about_section_one ] = useState([])
  const [about_section_two , set_about_section_two ] = useState([])
  const [about_section_three , set_about_section_three ] = useState([])
  const [about_section_four , set_about_section_four ] = useState([])
  const [base_url , set_base_url ] = useState("")
  const font_awesome_arr = [
    'fa fa-rebel','fa fa-users','fa fa-heart','fa fa-book',
    'fa fa-bus','fa fa-eercast','fa fa-hourglass-start',
   'fa fa-handshake-o', 'fa fa-certificate'
  ];

  
  const color_arr = [
    'orange','green','darkblue','lightblue'
  ];
   const get_data = () => {


   
      get_about({language_id : language_selected}).then(about_data => {
        set_about_section_one(about_data.find(arr=>arr.type == "aboutsectionone"));
        set_about_section_two(about_data.find(arr=>arr.type == "aboutsectiontwo"));
        set_about_section_three(about_data.find(arr=>arr.type == "aboutsectionthree"));
        set_about_section_four(about_data.find(arr=>arr.type == "aboutsectionfour"));
      });
    
      get_base_url({language_id : language_selected}).then(base_url => {

        set_base_url(base_url)
      })
      

    
      

   }

   useEffect(()=>{
      get_data()
   },[language_selected])
    return (
    <div>
        <header id="home" style={{height: '60vh'}}>
          {/* Background Image */}
          <div className="bg-img" style={{backgroundImage: 'url("./img/background1.jpg")'}}>
            <div className="overlay" />
          </div>
          {/* /Background Image */}
          {/* Nav */}
          <Header></Header>

          {/* /Nav */}
          {/* home wrapper */}
          <div className="home-wrapper">
            <div className="container">
              <div className="row">
                {/* home content */}
                <div className="col-md-10 col-md-offset-1">
                  <div className="home-content">
                    <h1 className="white-text">{t('aboutus')}</h1>
                    {/* <p className="white-text">Morbi mattis felis at nunc. Duis viverra diam non justo. In nisl. Nullam sit amet magna in magna gravida vehicula. Mauris tincidunt sem sed arcu. Nunc posuere.
                    </p> */}
                    <a className="main-btn" href="/about#about_section">{t('getstarted')}</a>
                  </div>
                </div>
                {/* /home content */}
              </div>
            </div>
          </div>
          {/* /home wrapper */}
        </header>
        {/* /Header */}
        {/* About */}
        <div>
      
        <div className="site-wrap" id="home-section">
        {(about_section_one) != null  ? 

          <section className="about-section" id="about_section">
            <div className="container">
              <div className="row">
                <div className="content-column" style={{width:'100%'}}>
                  <div className="inner-column container">
                    <div className="container">
                      <div className="sec-title" >
                        <span className="title" />
                        <div className="typewriter">
                          <h2 style={{fontSize : '31px'}}>{about_section_one.title} <span style={{font: 'weight:bold', color: 'blue'}}>{about_section_one.short_description}</span></h2>
                        </div>
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: about_section_one.description}}>

                      </div>
                    
                      {/*  <h2 class="text-black mb-4 h3 font-weight-bold">Our Mission and Values</h2>
							<p class="mb-4">Our mission at PieGen Software is to build new and innovative widescale products for users all around the globe.</p>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          :
          null}
     
          {(about_section_two) != null  ? 
          <div className="demo" style={{padding: '30px', background: 'radial-gradient(circle, rgba(63,94,251,0.3984944319524685) 0%, rgba(252,70,107,0) 100%)'}}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6 page" style={{background: 'radial-gradient(circle, rgba(63,94,251,0.3984944319524685) 0%, rgba(252,70,107,0) 100%)'}}>
                      <div className="sec-title">
                        <span className="title" />
                        <h1 className="workPrinciple text-center">{about_section_two.title}</h1>
                      </div>
                    </div>
                    <div className="col-md-6">
                    {Object.keys(about_section_two).length > 0 ? 


                    (about_section_two.new_list).map((prop, key) => {
                      return (

                      <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-default">
                          <div className="panel-heading" role="tab" id={"heading" + key}>
                            <h4 className="panel-title">
                              <a role="button" data-toggle="collapse" data-parent="#accordion" href={"#collapse" + key} >
                                <i className="fa fa-chevron-down" aria-hidden="true" />
                                {prop.outer_list}

                              </a>
                            </h4>
                          </div>
                          <div id={"collapse" + key} className="panel-collapse collapse in" role="tabpanel" aria-labelledby={"heading" + key}>
                            <div className="panel-body">
                              <p>
                              {prop.innerlist}

                              </p>
                            </div>
                          </div>
                        </div>
                        </div>


                        );
                        })

                        :
                        null
                        }
                        </div>
                        
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            :
            null
              }
            </div>

            {Object.keys(about_section_three).length > 0 ? 

          <section className="about-sectin">
            <div className="container">
              <div className="row">
                <div className="col-md-6 page" style={{position: 'relative'}}>
                  <h1 className="workPrinciple">{about_section_three.title}</h1>
                  <p className="text">
                  {about_section_three.short_description}
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="divList">
                    <ol>
                    {Object.keys(about_section_three).length > 0 ? 

                  (about_section_three.list).map((prop, key) => {
                    return (
                      <li>
                        <span className="divSpan">{key + 1}.</span>
                        <p><i className={font_awesome_arr[key]} aria-hidden="true" />
                          {prop}</p>
                      </li>
                         );
                        })
                      
                      :
                      null
                      }
                      
                     
                    </ol>
                  </div>
                </div>
              </div>
              <div className="text-center" style={{marginTop : '20px'}} dangerouslySetInnerHTML={{ __html: about_section_three.description}}>
              
              </div>
            </div>
          </section>
              :
              null
              }
              
          </div>
          
        {/* <div class="box box-2 cyan"></div>
			<div class="box box-3 tan"></div>
			<div class="box box-4 pink"></div>
			<div class="box box-5 tan"></div>
			<div class="box box-6 orange"></div>
			<div class="box box-7 cyan"></div>
			<div class="box box-8 green"></div>
			<div class="box box-9 pink"></div>
			<div class="box box-10 orange"></div>
			<div class="box box-11 navy"></div>
			<div class="box box-12 tan"></div> */}
          {Object.keys(about_section_four).length > 0 ? 

        <section className="about-sectin" style={{background: '#F5F5F5'}}>
          <div className="container">
            <div className="sec-title">
              <span className="title" />
              <h1 className="workPrinciple text-center">{about_section_four.title}</h1>
              {/* <hr></hr> */}
            </div>
            <div className="row">
              {/* <h1 class="text-center">Building blocks of logo</h1> */}
              <div className="col-md-6">
                <img width="100%" src={about_section_four.banner} alt="Image" className="img-fluid center" />
              </div>
              <div className="col-md-6">
                <div className="divList">
                  <ul className="mb-5 list-unstyled liLogo" style={{color: 'black'}}>
                    <br /><br />
                    {Object.keys(about_section_four).length > 0 ? 

                (about_section_four.list).map((prop, key) => {
                  return (
                    <>
                    <li><i className="fa fa-circle  fa-2x" style={{color: color_arr[key]}} aria-hidden="true" />
                      {/* Orange - <span style={{fontStyle: 'italic'}}>Passion and Creativity</span> */}
                      {prop}
                    </li>
                    <br></br>
                    </>

                     );
                    })
                  
                  :
                  null
                  }
                
                   
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      :
      
      null
      }
        {/* <ContactForm/> */}

        <Footer/>
      </div>


    );
}

