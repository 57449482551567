import React, { useState , useEffect } from "react";
// import './js/jquery.magnific-popup'
import {
  BrowserRouter as Router,
  Link,
} from 'react-router-dom';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle'
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { useTranslation } from 'react-i18next'

export default function ContactForm() {
  const { t, i18n } = useTranslation()

    return (

<div id="contact" className="section md-padding bg-grey">
            <div className="container">
              <div className="row">
                <div className="col-md-12">	
                  <div className="section-header text-center">
                    <h2 className="title">{t('getintouch')}</h2>
                  </div>
                </div>
                <div className="col-md-8 col-md-offset-2 mb-4">
                  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd_mki6zHCX-QlwDydo9f-wkgfWf9gejB9CzpvU0G_IrWtaiw/viewform?embedded=true" width="100%" height={707} frameBorder={0} marginHeight={0} marginWidth={0}>Loading…</iframe>            
                </div>
                <div className="col-sm-4">
                  <div className="contact">
                    <i className="fa fa-phone" />
                    <h3>{t('phone')}</h3>
                    <p>016-7889773 (Kenneth Wong)</p>
                    {/* <p>016-7488035 (Kelvin Keo)</p>      */}
                </div>
                </div>
                <div className="col-sm-4">
                  <div className="contact">
                    <i className="fa fa-envelope" />
                    <h3>{t('email')}</h3>
                    <p> Kwongwf17@outlook.com</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="contact">
                    <i className="fa fa-map-marker" />
                    <h3>{t('address')}</h3>
                    <p>121-01, Jalan Mutiara Emas 2A, Taman Mount Austin, 81100 Johor Bahru, Johor</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
 )
}