import React,{useEffect,useState} from 'react';
import Home from './Home'
import Blog from './Blog'
import BlogSingle from './BlogSingle'
import Portfolio from './Portfolio'
import PortfolioSingle from './PortfolioSingle'
import Services from './Services'
import About from './About'
import "./css/font.css"
import ScrollToTop from './ScrollToTop'
import './i18n'
import { LanguageProvider } from './languagecontext'

import "./css/bootstrap.css" 
import "./css/bootstrap.min.css"
import './css/owl.carousel.css'
import './css/owl.theme.default.css'
import "./css/magnific-popup.css"
import "./css/font-awesome.min.css"
import './css/style.css';
// import "./js/bootstrap.min.js"
// import "./js/owl.carousel.min.js"
// import "./js/jquery.magnific-popup.js"
// import './js/jquery.min.js'
// import "./js/main.js"
// import $ from 'jquery';
// import 'bootstrap/dist/css/bootstrap.css'
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle'
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "magnific-popup";


import {

  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
} from 'react-router-dom';


const COURSES = {
  0: {
    title: 'Road to React',
    url: 'http://roadtoreact.com/',
  },
  
  1: {
    title: 'Road to Firebase',
    url: 'http://roadtofirebase.com/',
  },
  2: {
    title: 'Road to GraphQL',
    url: 'http://roadtographql.com/',
  },
  3: {
    title: 'Road to Redux',
    url: 'http://roadtoredux.com/',
  },
};
// const App = () => (
//   return(

//   <Router>
//     {/* <Navigation /> */}
//     <Content />
//   </Router>
//   )
// );

const Content = () => (
  // <Routes basename={"/Piegen/"}>
    <Routes basename={process.env.PUBLIC_URL}> 
    <Route exact path="/" element={<Home />} />
    <Route path="/courses/*" element={<Course />} />
    <Route path="/about" element={<About />} />
    <Route path="/service" element={<Services />} />
    <Route path="/blog" element={<Blog />} />
    <Route path="/blog/:slug" element={<BlogSingle />} />
    
    <Route path="/portfolio" element={<Portfolio />} />

    <Route path="/portfolio/:slug" element={<PortfolioSingle />} exact />
    {/* <Route exact path={`${process.env.PUBLIC_URL}/`} element={<Home />} />
    <Route path={`${process.env.PUBLIC_URL}/about`} element={<About />} />
    <Route path={`${process.env.PUBLIC_URL}/service`} element={<Services />} />
    <Route path={`${process.env.PUBLIC_URL}/blog`} element={<Blog />} />
    <Route path={`${process.env.PUBLIC_URL}/blog/*`} element={<BlogSingle />} />
    <Route path={`${process.env.PUBLIC_URL}/portfolio`} element={<Portfolio />} />
    <Route path={`${process.env.PUBLIC_URL}/portfolio/*`} element={<PortfolioSingle />} exact /> */}


  </Routes>
);


// const About = () => <h1>My About Page</h1>;

const Course = () => (
  <>
    <h1>
      
      My Course List and Item Page (Note: Title shows up for both
      Pages)
    </h1>

    <Routes >
      <Route exact path="/" element={<CourseList />} />
      {/* <Route path="/:id" element={<CourseItem />} /> */}
    </Routes>
  </>
);

const CourseList = () => (
  <>
    <h2>All Courses</h2>
    <ul>
      {Object.keys(COURSES).map(key => (
        <li key={key}>
          Go to individual course route:&nbsp;
          <Link to={`/courses/${key}`}>{COURSES[key].title}</Link>
        </li>
      ))}
    </ul>
  </>
);

const App = () => {
  // const { id } = useParams();
  useEffect(()=>{

    $(window).on('load', function() {
      $("#preloader").delay(600).fadeOut();
    });
  
    ///////////////////////////
    // Scrollspy
    $('body').scrollspy({
      target: '#nav',
      offset: $(window).height() / 2
    });
  
    ///////////////////////////
    // Smooth scroll
    $("#nav .main-nav a[href^='#']").on('click', function(e) {
      alert("asdasdasd");
      e.preventDefault();
      var hash = this.hash;
      $('html, body').animate({
        scrollTop: $(this.hash).offset().top
      }, 600);
    });
  
    $('#back-to-top').on('click', function(){
      $('body,html').animate({
        scrollTop: 0
      }, 600);
    });
  
    ///////////////////////////
    // Btn nav collapse
    $('#nav .nav-collapse').on('click', function() {
      $('#nav').toggleClass('open');
    });
  
    ///////////////////////////
    // Mobile dropdown
    $('.has-dropdown a').on('click', function() {
      $(this).parent().toggleClass('open-drop');
    });
  
    ///////////////////////////
    // On Scroll
    $(window).on('scroll', function() {
      var wScroll = $(this).scrollTop();
  
      // Fixed nav
      wScroll > 1 ? $('#nav').addClass('fixed-nav') : $('#nav').removeClass('fixed-nav');
  
      // Back To Top Appear
      wScroll > 700 ? $('#back-to-top').fadeIn() : $('#back-to-top').fadeOut();
    });
  
    ///////////////////////////
    // magnificPopup
    // $('.work').magnificPopup({
    //   delegate: '.lightbox',
    //   type: 'image'
    // });
  
    ///////////////////////////
 
    // $('#testimonial-slider').owlCarousel({
    //   loop:true,
    //   margin:15,

    //   dots : true,
    //   nav: false,
    //   autoplay : true,
    //   responsive:{
    //     0: {
    //       items:1
    //     },
    //     992:{
    //       items:2
    //     }
    //   }
    // });

  },[])
  return (
    <Router  basename={process.env.PUBLIC_URL}>
      			<LanguageProvider>

          {/* // <Router basename={"/Piegen/"}>  */}
          <ScrollToTop />

      {/* history={browserHistor  y} basename={'foobar'} */}
    {/* <Navigation /> */}
    <Content />
    </LanguageProvider>

  </Router>
    // <>
    //   <h2>{COURSES[id].title}</h2>
    //   <p>

    //     Go to <a href={COURSES[id].url}>Course</a>
    //   </p>

    //   <p>
    //     Back to <Link to="/courses">Courses</Link>
    //   </p>
    // </>
  );
};

export default App;
