
import React, { useState , useEffect,useContext } from "react";
import Header from './Header'
import ContactForm from "./ContactForm";
import Footer from './Footer'

import {get_base_url,get_service} from './API/api';
import { useTranslation } from 'react-i18next'
import { LanguageContext } from './languagecontext'


export default function Services() {
   const [service , set_service ] = useState([])
   const [service_first , set_service_first ] = useState([])
   const { t, i18n } = useTranslation()
   const {language_selected} = useContext(LanguageContext);

   const [base_url , set_base_url ] = useState("")

   const get_data = () => {
 
      get_base_url({language_id : 2}).then(base_url => {
        set_base_url(base_url)
      })
    
      get_service({language_id : 2,isHome : 0 , isFirst : 1}).then(service_data => {
          set_service_first(service_data.find(arr=>arr.isFirst == "1"))        
      })
      get_service({language_id : 2,isHome : 0 , isFirst : 0}).then(service_data => {
        set_service(service_data)
        
      })
   }

   useEffect(()=>{
      get_data()
   },[])
  
    return (
        <div>
        <header id="home" style={{height: '60vh'}}>
          {/* Background Image */}
          <div className="bg-img" style={{backgroundImage: 'url("./img/background1.jpg")'}}>
            <div className="overlay" />
          </div>
          {/* /Background Image */}
          {/* Nav */}
          <Header></Header>
        
          {/* /Nav */}
          {/* home wrapper */}
          <div className="home-wrapper">
            <div className="container">
              <div className="row">
                {/* home content */}
                <div className="col-md-10 col-md-offset-1">
                  <div className="home-content">
                    <h1 className="white-text">{t('service')}</h1>
                    {/* <p className="white-text">Morbi mattis felis at nunc. Duis viverra diam non justo. In nisl. Nullam sit amet magna in magna gravida vehicula. Mauris tincidunt sem sed arcu. Nunc posuere.
                    </p> */}
                    <a className="main-btn" href="/service#service_section">{t('getstarted')}</a>
                    {/* <button class="main-btn">Learn more</button> */}
                  </div>
                </div>
                {/* /home content */}
              </div>

            </div>
          </div>
          {/* /home wrapper */}
        </header>
        {/* /Header */}
        {/* About */}
        <video width={640} height={320} controls id="myVideo" style={{display: 'none'}}>
          <source src="https://www.html5rocks.com/en/tutorials/video/basics/Chrome_ImF.mp4" type="video/mp4" />
          <source src="https://www.html5rocks.com/en/tutorials/video/basics/Chrome_ImF.webm" type="video/webm" />
          <source src="https://www.html5rocks.com/en/tutorials/video/basics/Chrome_ImF.ogv" type="video/ogg" />
          Your browser doesn't support HTML5 video tag.
        </video>
        <div className="section md-padding" id="service_section">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center mb-5">
                <h2 className="mb-4 section-title">{t('oursevice')}</h2>
              </div>
              <div className="col-lg-6 mr-auto mb-5">
                {/*/Row*/}
                <span className="d-block text-uppercase text-secondary">{service_first.small_title}</span>
                <span className="divider my-4" />
                <h2 className="mb-4 section-title">{service_first.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: service_first.description}}>

                </div>
                <p><a href="/service#contact" className="btn btn-outline-black">{service_first.button_text}</a></p>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  {/* Section header */}
                  {/* /Section header */}
                  {/* about */}
                  {Object.keys(service).length > 0 ? 
                  (service).map((prop, key) => {
                    return (
                  <div className="col-md-6">
                    <div className="about text-left">
                      <i className={prop.logo} />
                      <h3>{prop.title}</h3>
                      <div dangerouslySetInnerHTML={{ __html: prop.description}}>

                </div>
                    </div>
                  </div>
                  );
                })
                     :
                     null
                     }
                  {/* /about */}
                  {/* about */}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Service */}
        <div id="service" className="section bg-grey md-padding d-none">
          {/* Container */}
          <div className="container">
            {/* Row */}
            <div className="row">
              {/* Section header */}
              <div className="col-md-12">
                <div className="section-header text-center">
                  <h2 className="title">What ARE OUR SERVICE OFFER</h2>
                </div>
              </div>
              {/* /Section header */}
              {/* service */}
              <div className="col-md-4 col-sm-6">
                <div className="service">
                  <i className="fa fa-diamond" />
                  <h3>App Development</h3>
                  <p>Maecenas tempus tellus eget condimentum rhoncus sem quam semper libero.</p>
                </div>
              </div>
              {/* /service */}
              {/* service */}
              <div className="col-md-4 col-sm-6">
                <div className="service">
                  <i className="fa fa-rocket" />
                  <h3>Graphic Design</h3>
                  <p>Maecenas tempus tellus eget condimentum rhoncus sem quam semper libero.</p>
                </div>
              </div>
              {/* /service */}
              {/* service */}
              <div className="col-md-4 col-sm-6">
                <div className="service">
                  <i className="fa fa-cogs" />
                  <h3>Creative Idea</h3>
                  <p>Maecenas tempus tellus eget condimentum rhoncus sem quam semper libero.</p>
                </div>
              </div>
              {/* /service */}
              {/* service */}
              <div className="col-md-4 col-sm-6">
                <div className="service">
                  <i className="fa fa-diamond" />
                  <h3>Marketing</h3>
                  <p>Maecenas tempus tellus eget condimentum rhoncus sem quam semper libero.</p>
                </div>
              </div>
              {/* /service */}
              {/* service */}
              <div className="col-md-4 col-sm-6">
                <div className="service">
                  <i className="fa fa-pencil" />
                  <h3>Awesome Support</h3>
                  <p>Maecenas tempus tellus eget condimentum rhoncus sem quam semper libero.</p>
                </div>
              </div>
              {/* /service */}
              {/* service */}
              <div className="col-md-4 col-sm-6">
                <div className="service">
                  <i className="fa fa-flask" />
                  <h3>Brand Design</h3>
                  <p>Maecenas tempus tellus eget condimentum rhoncus sem quam semper libero.</p>
                </div>
              </div>
              {/* /service */}
            </div>
            {/* /Row */}
          </div>
          {/* /Container */}
        </div>
        {/* /Service */}
        {/* Numbers */}
        {/* <ContactForm/> */}
        <Footer/>
        {/* /Footer */}
        {/* Back to top */}
        <div id="back-to-top" />
        {/* /Back to top */}
        {/* Preloader */}
        {/* <div id="preloader">
          <div className="preloader">
            <span />
            <span />
            <span />
            <span />
          </div>
        </div> */}
      </div>
    

    );
}
