import React, { createContext, useState } from 'react'

export const LanguageContext = createContext();

export const LanguageProvider = ({children}) => {
    const [language_selected,set_language_selected] = useState(2)
   

    return (

      <LanguageContext.Provider
          value={{
            language_selected,
            set_language_selected,
          
            
          }}>
        {children}
      </LanguageContext.Provider>
    
);
  
        
}
  
